<template>
  <el-dialog
    v-bind="$attrs"
    width="60%"
    v-on="$listeners"
    @open="open"
  >
    <div>
      <el-form
        ref="form"
        v-loading="loading"
        size="small"
        :rules="formRules"
        :model="formObj"
        label-suffix="："
        label-width="120px"
      >
        <el-form-item prop="dataServiceId" label="选择活动">
          <el-select v-model="formObj.dataServiceId" clearable placeholder="选择活动">
            <el-option
              v-for="item in activityList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="pushLocation" label="分发渠道">
          <el-radio-group v-model="formObj.pushLocation">
            <el-radio v-for="item in pushLocationList.list" :key="item.label" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="pushType" label="推送类型">
          <el-radio-group v-model="formObj.pushType">
            <el-radio :label="2">定向推送</el-radio>
            <el-radio :label="1">全平台推送</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="formObj.pushType === 2" label="推送名单">
          <el-table
            :data="formObj.pushOrgAndUserIdsVOS"
            border
            style="width: 100%;"
            max-height="300px"
          >
            <el-table-column prop="prop" label="组织名称" width="200px">
              <template slot-scope="scoped">
                <el-form-item v-if="scoped.row.isEdit" :prop="'pushOrgAndUserIdsVOS.' + scoped.$index + '.orgId'" :rules="formRules.pushOrgAndUserIdsVOS.orgId">
                  <OrgList
                    :show-all-levels="false"
                    :opt-props="{ multiple: false }"
                    :org-list="[scoped.row.orgId].filter(item => item)"
                    style="display: block;"
                    @choose-list="chooseList($event, scoped)"
                  />
                </el-form-item>
                <span v-else>{{ scoped.row.orgName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="prop" label="被推送人账号/用户名">
              <template slot-scope="scoped">
                <el-form-item v-if="scoped.row.isEdit" :prop="'pushOrgAndUserIdsVOS.' + scoped.$index + '.userIds'" :rules="formRules.pushOrgAndUserIdsVOS.userIds">
                  <el-select
                    v-model="scoped.row.userIds"
                    class="collapse-tags"
                    value-key="userId"
                    multiple
                    collapse-tags
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in scoped.row.orgUserList"
                      :key="item.userId"
                      :label="`${item.name?(item.name + '/'):''}${item.login}`"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
                <template v-else>
                  <el-tag
                    v-for="item in scoped.row.userIds"
                    :key="item.userId"
                    :disable-transitions="true"
                    style="margin: 0 5px 5px 0;"
                    type="info"
                  >
                    {{ item.userName?(item.userName+'/'):'' }}{{ item.login }}
                  </el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="prop"
              label="操作"
              width="150px"
            >
              <template slot-scope="scoped">
                <el-button v-if="!scoped.row.isEdit" @click="finishEdit(scoped, true)">编辑</el-button>
                <el-button v-else type="primary" @click="finishEdit(scoped, false)">完成</el-button>
                <el-button type="danger" @click="handleDel(scoped)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-button
              size="medium"
              style="width: 100%; height: 50px; margin-top: 10px;"
              type="primary"
              @click="formObj.pushOrgAndUserIdsVOS.push({ custom_id: $nanoid(), isEdit: true, userIds: [], orgId: '', orgUserList: [] })"
            >
              添加推送人
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" style="text-align: center; border-top: 1px solid #ddd; padding-top: 20px;">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="onSubmit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import {chartConfig} from '@/utils/staticData'
export default {
  name: 'CreatePushDialog',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      activityList: [],
      formRules: {
        dataServiceId: [{ message: '请选择数据服务', required: true, target: 'change' }],
        pushLocation: [{ message: '请选择推送渠道', type: 'number', required: true, target: 'blue' }],
        pushType: [{ message: '请选择推送类型', type: 'number', required: true, target: 'blue' }],
        pushOrgAndUserIdsVOS: {
          orgId: [{ message: '请选择组织', required: true, target: 'blue' }],
          userIds: [{ message: '请选择被人账号', type: 'array', required: true, target: 'blue' }]
        }
      },
      formObj: {
        dataServiceId: '',
        pushLocation: 1,
        pushType: 2,
        pushOrgAndUserIdsVOS: []
      },
      pushLocationList: chartConfig.pushLocationList
    }
  },
  mounted() {},
  methods: {
    open() {
      this.getActivityList()
      this.formObj = this.$options.data().formObj
      this.$nextTick(() => {
        this.$refs.form?.resetFields()
      })
      if (this.id) {
        this.getPushDetail(this.id)
      }
    },
    // 获取详情
    async getPushDetail(dataServicePushId) {
      this.loading = true
      try {
        const params = { dataServicePushId }
        const res = await this.$axios.get(this.$API.dataServicePushDetail, { params })
        res.pushOrgAndUserIdsVOS = res.pushUserInfos?.map(item => {
          item.custom_id = this.$nanoid()
          item.isEdit = false
          item.orgUserList = []
          item.userIds = item.userIdAndNames.map(userIdAndNamesItem => {
            userIdAndNamesItem.login = userIdAndNamesItem.loginName
            return userIdAndNamesItem
          })
          return item
        }) || []
        this.formObj = res
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    // 活动列表
    async getActivityList() {
      try {
        const params = {
          pageNo: 1,
          pageSize: 99999,
          dataServiceTitle: '',
          isPublish: true
        }
        const { data } = await this.$axios.get(this.$API.dataServiceQueryList, { params })
        this.activityList = data
      } catch (error) {
        console.log(error)
      }
    },
    // 获取某组织成员
    async getOrgUserList(orgId) {
      if (!orgId) return
      try {
        const params = { orgId }
        const data = await this.$axios.get(this.$API.queryOrgUser, { params })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    // 处理组织选择
    async chooseList(event, scoped) {
      scoped.row.orgId = event[0]?.queryObjectId || ''
      scoped.row.orgName = event[0]?.queryObjectShowTitle || ''
      scoped.row.userIds = []
      scoped.row.orgUserList = await this.getOrgUserList(scoped.row.orgId)
    },
    // 编辑和完成
    async finishEdit(scoped, isEdit) {
      let orgIdValid = new Promise((resolve, reject) => {
        if (!isEdit) {
          this.$refs.form.validateField([`pushOrgAndUserIdsVOS.${scoped.$index}.orgId`], errorMsg => {
            if (errorMsg) {
              reject(errorMsg)
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
      let userIdsValid = new Promise((resolve, reject) => {
        if (!isEdit) {
          this.$refs.form.validateField([`pushOrgAndUserIdsVOS.${scoped.$index}.userIds`], errorMsg => {
            if (errorMsg) {
              reject(errorMsg)
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
      try {
        await Promise.all([orgIdValid, userIdsValid])
        for (let i = 0; i < this.formObj.pushOrgAndUserIdsVOS.length; i++) {
          const item = this.formObj.pushOrgAndUserIdsVOS[i]
          if (scoped.row.custom_id === item.custom_id) {
            item.isEdit = isEdit
            if (isEdit) {
              item.orgUserList = await this.getOrgUserList(item.orgId)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 删除
    handleDel(scoped) {
      this.formObj.pushOrgAndUserIdsVOS = this.formObj.pushOrgAndUserIdsVOS.filter(item => {
        return scoped.row.custom_id !== item.custom_id
      })
      this.$forceUpdate()
    },
    // 提交
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const params = cloneDeep(this.formObj)
            params.pushOrgAndUserIdsVOS = params.pushOrgAndUserIdsVOS.map(item => {
              delete item.custom_id
              delete item.isEdit
              delete item.orgUserList
              delete item.orgName
              item.userIds = item.userIds.map(item => item.userId)
              return item
            })
            await this.$axios.post(this.$API.dataServicePushSaveOrUpdate, params)
            this.formObj = this.$options.data().formObj
            this.$parent.getDataList()
            this.resetForm()
            this.loading = false
          } catch (error) {
            this.$message.error(error.message)
            this.loading = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置表单
    resetForm() {
      // this.$refs.form.resetFields()
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse-tags {
  :deep(.el-select__tags) {
    > span {
      width: 100%;
      display: flex;
      > span:nth-child(1) {
        max-width: 80%;
      }
    }
  }
}
</style>
