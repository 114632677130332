<template>
  <div class="report-list">
    <div class="search-box">
      <el-form :model="search" inline label-width="80px">
        <el-form-item label="服务名称">
          <el-input
            v-model="search.dataServiceTitle"
            v-debounce="[onSearch, 'input']"
            class="input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="输入活动名称"
            @clear="onSearch"
            @keydown.enter.native="onSearch"
          />
        </el-form-item>
        <el-form-item label="推送状态">
          <el-select
            v-model="search.status"
            placeholder="请选择推送状态"
            clearable
            @change="onSearch"
          >
            <el-option label="未推送" :value="0" />
            <el-option label="已推送" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="分发渠道">
          <el-select
            v-model="search.pushLocation"
            clearable
            placeholder="请选择分发渠道"
            @change="onSearch"
          >
            <el-option
              v-for="item in pushLocationList.list"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="推送类型">
          <el-select
            v-model="search.pushType"
            clearable
            placeholder="请选择推送类型"
            @change="onSearch"
          >
            <el-option label="全平台推送" :value="1" />
            <el-option label="定向推送" :value="2" />
          </el-select>
        </el-form-item>
      </el-form>

      <div>
        <el-button @click="onEdit()">新建推送</el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="data"
      border
      style="width: 100%;"
    >
      <el-table-column prop="dataServiceTitle" label="服务名称" />
      <el-table-column prop="status" label="服务推送状态">
        <template slot-scope="{ row }">
          {{ row.status === 0 ? '未推送' : row.status === 1 ? '已推送' : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="pushLocation" label="分发渠道">
        <template slot-scope="{ row: { pushLocation } }">{{ pushLocationList.get('label', pushLocation) }}</template>
      </el-table-column>
      <el-table-column prop="pushType" label="推送类型">
        <template slot-scope="{ row: { pushType } }">{{ pushType === 1 ? '全平台推送' : pushType === 2 ? '定向推送' : '' }}</template>
      </el-table-column>
      <el-table-column prop="pushAt" label="推送时间" />
      <el-table-column prop="creatorName" label="创建人" />
      <el-table-column prop="auditorName" label="审核人" />
      <el-table-column label="操作" width="250px">
        <template slot-scope="scoped">
          <el-button size="mini" @click="onEdit(scoped.row)">编辑</el-button>
          <el-button
            v-if="scoped.row.status === 1"
            size="mini"
            type="primary"
            @click="changePublishStatus(scoped.row, false)"
          >
            取消推送
          </el-button>
          <el-button
            v-if="scoped.row.status === 0"
            size="mini"
            type="primary"
            @click="changePublishStatus(scoped.row, true)"
          >
            推送
          </el-button>
          <el-button size="mini" type="danger" @click="onDel(scoped.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <CreatePushDialog :id="editId" :title="dialogTitle + '推送'" :visible.sync="dialogVisible" />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import CreatePushDialog from './components/createPushDialog.vue'
import {chartConfig} from '@/utils/staticData'
export default {
  name: 'ReportList',
  components: { CreatePushDialog },
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      search: {
        pushLocation: '',
        status: ''
      },
      data: [],
      dialogVisible: false,
      dialogTitle: '',
      editId: '',
      pushLocationList: chartConfig.pushLocationList
    }
  },
  computed: {},
  mounted() {
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.search)
        const { data, totalCount } = await this.$axios.get(this.$API.dataServicePushQueryList, { params })
        this.data = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    // 编辑
    async onEdit(row = {}) {
      if (row.id) {
        this.dialogTitle = '编辑'
      } else {
        this.dialogTitle = '新建'
      }
      this.editId = row?.id || ''
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    // 修改 推送状态
    async changePublishStatus({ id: dataServicePushId }, isPass) {
      try {
        const params = { isPass, dataServicePushId }
        await this.$axios({
          method: 'post',
          url: this.$API.dataServicePushChangeAuthSataus,
          params
        })
        let message = `${isPass ? '推送' : '取消'}成功`
        this.$message.success(message)
        this.getDataList()
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 删除
    async onDel(row) {
      try {
        const params = {
          dataServicePushId: row.id
        }
        await this.$axios({
          url: this.$API.dataServicePushDel,
          method: 'post',
          params
        })
        this.$message.success('删除成功')
        this.getDataList()
      } catch (error) {
        this.$message.error(error.message)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.report-list {
  .search-box {
    display: flex;
    justify-content: space-between;
  }
}
</style>
