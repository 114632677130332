export const chartConfig = {
  // 分发渠道
  pushLocationList: {
    get(type, key) {
      if (type === 'label') {
        return this.list.find(item => item.value === key).label
      } else if (type === 'value') {
        return this.list.find(item => item.label === key).value
      }
    },
    list: [{
      label: '服务中心',
      value: 1
    }, {
      label: '系统推荐看板',
      value: 2
    }, {
      label: '科会数据报告',
      value: 3
    },
    // {
    //   label: '调研数据报告',
    //   value: 4
    // },
    {
      label: '直播数据报告',
      value: 5
    }, {
      label: '会议数据报告',
      value: 6
    }, {
      label: '委托数据报告',
      value: 7
    }]
  }
}
